import Web3 from "web3";
import Big from "big.js";

import abiMarket from "@/applibs/exchange/abi.js";
import abiToken from "@/applibs/erc20/abi.js";

var utama = async function (
  self,
  tokenAddress,
  marketId,
  sellIds,
  qtyBs,
  final_normal_buy
) {
  try {
    console.log("final_normal_buy", final_normal_buy);

    var qty_sisa = "0";
    // var normal_price = '0';

    // Untuk mencegah valuenya adalah 4736842140.751207
    // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
    // maka akan tidak akan terkirim juga karena saldo tidak cukup
    var qty_arr = [];
    let qtyBsSum = Big("0");
    qtyBs.forEach((ele) => {
      if (ele.split(".").length == 2) {
        var apo = ele.split(".");
        qty_arr.push(apo[0]);
        qtyBsSum = qtyBsSum.add(Big(apo[0]));
      } else {
        qty_arr.push(ele);
        qtyBsSum = qtyBsSum.add(Big(ele));
      }
    });

    var fee_send = Big(self.market_fee).mul(sellIds.length).toString();

    if (final_normal_buy != null) {
      // cegah error koma
      // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
      // maka akan tidak akan terkirim juga karena saldo tidak cukup
      qty_sisa = final_normal_buy["qty_b"].split(".")[0];
      // normal_price = final_normal_buy["price"]

      // kita anggap saja yang terakhir adalah yang paling besar price-nya
      // TODO: Hilangkan pesan diatas jika function ini dipanggil dengan mengurutkan dari yang kecil ke yang besar untuk price
      const last_qty = qty_arr[qty_arr.length - 1];
      const last_qty_send = Big(last_qty).add(qty_sisa);

      // replace last qty
      qty_arr.pop();
      qty_arr.push(last_qty_send.toString());
    }

    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];

    const marketAddress = self.sc_address;
    const marketContract = new web3.eth.Contract(abiMarket, marketAddress);
    const tokenContract = new web3.eth.Contract(abiToken, tokenAddress);

    const deadline = Math.floor(Date.now() / 1000) + 60 * 60;

    const nonce = await tokenContract.methods.nonces(account).call();
    const tokenName = await tokenContract.methods.name().call();
    console.log("tokenName", tokenName);

    // const domainSeparator = await tokenContract.methods.DOMAIN_SEPARATOR().call();
    // console.log('domainSeparator', domainSeparator);

    const chainId = await web3.eth.getChainId();

    const domainType = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];

    // permit untuk ini harus ditambah dengan qty_sisa
    const spender_value = qtyBsSum.add(qty_sisa);

    const permitData = {
      owner: account,
      spender: marketAddress,
      value: spender_value.toString(),
      nonce: nonce,
      deadline: deadline,
    };

    console.log("permitData", permitData);

    const domain = {
      name: tokenName,
      version: "1",
      chainId: chainId,
      verifyingContract: tokenAddress,
    };

    const types = {
      EIP712Domain: domainType,
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    };

    const message = permitData;

    const data = JSON.stringify({
      types: types,
      domain: domain,
      primaryType: "Permit",
      message: message,
    });

    const signature = await window.ethereum.request({
      method: "eth_signTypedData_v4",
      params: [account, data],
    });

    const r = signature.slice(0, 66);
    const s = "0x" + signature.slice(66, 130);
    let v = web3.utils.hexToNumber("0x" + signature.slice(130, 132));

    if (v < 27) {
      v += 27;
    }

    console.log({ sellIds, qty_arr, marketId, deadline, v, r, s, fee_send });

    let sendOptions;
    if (chainId == "247253") {
      // saakuru testnet
      sendOptions = {
        from: account,
        value: fee_send,
        gasPrice: "0x0",
      };
    } else if (chainId == "7225878") {
      // saakuru main net
      sendOptions = {
        from: account,
        value: fee_send,
        gasPrice: "0x0",
      };
    } else {
      sendOptions = {
        from: account,
        value: fee_send,
      };
    }

    await marketContract.methods
      .buyPump(sellIds, qty_arr, `${marketId}`, deadline, v, r, s)
      .send(sendOptions);

    return true;
  } catch (error) {
    console.log(error);

    if (error.code == 4001) {
      return true;
    } else {
      return false;
    }
  }
};

export default utama;
