// node_modules
import axios from "axios";

/**
 * Pada bagian ini agak berbeda sedikit. 
 * Kita harus ambil fee dari evm app karena fee pada aplikasi bot tidak bisa diambil dari wallet web3
 * @returns
 */
const utama = async function (rpc_host, sc_address) {
  const res = await axios({
    method: "get",
    url: `${process.env.VUE_APP_BASE_EVM}/exchange-data/fee`,
    params: {
      rpc_host,
      sc_address,
    },
  });
  console.log("res", res.data);
  return res.data.data;
};

export default utama;
