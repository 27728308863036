<template>
  <main>
    <div class="footer_wrapper">
      <footer class="footer_row">
        <div class="footer_container">
          <div class="footer_item">
            <div class="footer_left">
              <img src="/images/dexgood-logo.png" width="70" alt="" />
              <h3>YOUR GATEWAY TO THE FUTURE WORLD!</h3>
              <p class="x-footer-web-info">
                Web3, trusted and transparent cryptocurrency exchange
              </p>
            </div>
          </div>

          <div class="footer_item">
            <div>
              <h5>About</h5>
              <ul class="nav flex-column">
                <li class="nav-item mb-2">
                  <router-link to="/p/faq" class="nav-link p-0 text-muted"
                    >FAQ</router-link
                  >
                </li>
                <li class="nav-item mb-2">
                  <router-link
                    to="/p/term-of-service"
                    class="nav-link p-0 text-muted"
                    >Term of Service</router-link
                  >
                </li>
                <li class="nav-item mb-2">
                  <a
                    :href="`${explorer}/address/${marketAddress}`"
                    class="nav-link p-0 text-muted"
                    target="_blank"
                    >Explorer</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="footer_item">
            <div>
              <h5>Learn</h5>
              <ul class="nav flex-column">
                <li class="nav-item mb-2">
                  <a
                    href="https://docs.dexgood.com"
                    target="_blank"
                    class="nav-link p-0 text-muted"
                  >
                    Academy
                  </a>
                </li>
                <li class="nav-item mb-2">
                  <router-link to="/marketplace" class="nav-link p-0 text-muted"
                    >Wallet</router-link
                  >
                </li>
                <li class="nav-item mb-2">
                  <router-link to="/about-us" class="nav-link p-0 text-muted"
                    >Create Market</router-link
                  >
                </li>
                <li class="nav-item mb-2">
                  <router-link to="/user/nfts" class="nav-link p-0 text-muted"
                    >Buy and Sell</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>

    <div class="footer_bottom">
      <div class="copy_right d-flex justify-content-between">
        <span class="my-auto">@ 2024 DexGood</span>

        <div>
          <a
            href="https://t.me/dexgood_exchange"
            target="_blank"
            class="social_icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 32 32"
              fill="none"
            >
              <g clip-path="url(#clip0_19_6084)">
                <path
                  d="M21.5 0H10.5C4.70101 0 0 4.70101 0 10.5V21.5C0 27.299 4.70101 32 10.5 32H21.5C27.299 32 32 27.299 32 21.5V10.5C32 4.70101 27.299 0 21.5 0Z"
                  fill="white"
                  fill-opacity="0.12"
                />
                <path
                  d="M21.5 0.437988H10.501C4.94335 0.437988 0.437988 4.94335 0.437988 10.501V21.5C0.437988 27.0576 4.94335 31.563 10.501 31.563H21.5C27.0576 31.563 31.563 27.0576 31.563 21.5V10.501C31.563 4.94335 27.0576 0.437988 21.5 0.437988Z"
                  stroke="url(#paint0_linear_19_6084)"
                  stroke-opacity="0.4"
                  stroke-width="0.875"
                />
                <path
                  d="M21.5 0.437988H10.501C4.94335 0.437988 0.437988 4.94335 0.437988 10.501V21.5C0.437988 27.0576 4.94335 31.563 10.501 31.563H21.5C27.0576 31.563 31.563 27.0576 31.563 21.5V10.501C31.563 4.94335 27.0576 0.437988 21.5 0.437988Z"
                  stroke="url(#paint1_linear_19_6084)"
                  stroke-opacity="0.4"
                  stroke-width="0.875"
                />
                <path
                  d="M22.6361 9.64105C22.6361 9.64105 24.1161 9.06405 23.9931 10.4661C23.9521 11.0431 23.5821 13.0631 23.2931 15.2481L22.3071 21.7201C22.3071 21.7201 22.2251 22.6681 21.4851 22.8331C20.7451 22.9981 19.6351 22.2561 19.4291 22.0911C19.2651 21.9671 16.3451 20.1121 15.3181 19.2051C15.0301 18.9581 14.7011 18.4631 15.3581 17.8861L19.6761 13.7641C20.1691 13.2691 20.6621 12.1141 18.6061 13.5161L12.8511 17.4331C12.8511 17.4331 12.1931 17.8451 10.9591 17.4731L8.28711 16.6491C8.28711 16.6491 7.30011 16.0311 8.98611 15.4131C13.0971 13.4751 18.1541 11.4961 22.6361 9.64105Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_19_6084"
                  x1="32"
                  y1="36.952"
                  x2="16"
                  y2="16.762"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0.12" />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_19_6084"
                  x1="14.857"
                  y1="16.762"
                  x2="1.14299"
                  y2="-3.81001"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0" />
                  <stop offset="1" stop-color="white" stop-opacity="0.34" />
                </linearGradient>
                <clipPath id="clip0_19_6084">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// node_modules

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

export default {
  components: {},
  data() {
    return {
      themain,
      explorer: thestore.getBlockchainExplorerDomain(),
      marketAddress: process.env.VUE_APP_EXCHANGE_ADDRESS,
    };
  },

  async mounted() {},

  methods: {},
};
</script>

<style scoped>
.footer_wrapper {
  background-color: #182d36;
  display: flex;
  justify-content: center;
}

.footer_row {
  width: 1400px;
}

.footer_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 50px 2rem;
}

.footer_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6c757d;
}

.footer_item h5 {
  font-weight: 600;
}

.footer_left img {
  margin-bottom: 20px;
}

.footer_bottom {
  display: flex;
  justify-content: center;
  background-color: #0f2027;
}

.copy_right {
  width: 1400px;
  border-top: 3px solid #254451;
  padding: 20px 2rem;
  color: #6c757d;
}

.x-footer {
  padding-top: 46px;
  padding-bottom: 20px;
  margin-top: 60px;
  /* position: fixed;
  width: 100%;
  bottom: 0; */
}
ul.x-pages {
  list-style-type: none;
  padding-left: 0px;
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight: 300;
}
ul.x-pages a {
  text-decoration: none;
}

.x-footer-bottom-section {
  border-top: 1px solid #fff;
  padding-top: 10px;
}

.x-footer-web-info {
  font-size: 0.8rem;
}

.text-muted:hover {
  color: #1bdbdb !important;
}

.social_icon {
  opacity: 65%;
  transition: all 0.2s ease;
}
.social_icon:hover {
  opacity: 100%;
}

@media only screen and (max-width: 1400px) {
  /* .copy_right {
    margin-left: 20px;
  } */
}

@media only screen and (max-width: 1035px) {
  .footer_left {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .footer_container {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 1.5rem 1rem;
  }

  .footer_left {
    padding-left: 0;
  }

  .footer_item {
    align-items: flex-start;
    /* padding-left: 20px; */
  }

  .copy_right {
    padding: 1.5rem 1rem;
  }
}
</style>
