<template>
  <div class="market-trade-buy h-100 d-flex flex-column p-0 pr-md-3">
    <div class="row mb-2">
      <div class="col-5 text-light">
        Fee:
        <p class="mt-0">{{ marketFee }} {{ rootCoinSymbol }}</p>
      </div>
      <div class="col-7 text-light text-right">
        Available:
        <p class="mt-0">
          {{ parseFloat(buyForm.tokenBOwnedHumanView).toFixed(4) }}
          {{ simbol_b }}
        </p>
      </div>
    </div>

    <!-- price -->
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Price</span>
      </div>
      <input
        id="buy_form_price"
        type="text"
        step="any"
        class="form-control text-right"
        v-model="buyForm.price"
        @keyup="buyBtnClicked(false)"
      />
      <div class="input-group-append">
        <span class="input-group-text" style="width: 4rem;">{{ simbol_b }}</span>
      </div>
    </div>

    <!-- qty A -->
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Amount</span>
      </div>
      <input
        id="buy_form_qty"
        type="text"
        step="any"
        class="form-control text-right"
        v-model="buyForm.qtyA"
        @keyup="buyBtnClicked(false)"
      />
      <div class="input-group-append">
        <span class="input-group-text" style="width: 4rem">{{ simbol_a }}</span>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col text-right text-light">
        Estimation: {{ buyForm.qtyAEstimation }} {{ simbol_a }}
      </div>
    </div> -->

    <div class="row mt-md-auto" :class="mtipe === '2' && 'margin_bottom'">
      <div class="col-12 d-flex">
        <button
          id="buy_form_submit_btn"
          class="btn buy"
          @click="buyBtnClicked(true)"
        >
          Bot Buy
        </button>
        <button @click="() => { $emit('swap-form') }" id="com-buy-close-form" class="btn sell ml-3" style="width: 15%;">
          <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"/>
          </svg>
        </button>
      </div>
      <div
        @click="allowanceBtnClicked"
        class="col-12 cursor-pointer allowance-row"
        v-if="mtipe != '2'"
      >
        <i class="icon ion-md-checkmark-circle"></i> Allowance: &nbsp;&nbsp;
        {{ parseFloat(allowance_human).toFixed(4) }} {{ simbol_b }}
      </div>
    </div>

    <!-- modal allowance -->
    <div
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      :class="{ show: modalShow }"
      :style="modalStyle"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Allowance</h5>
            <button
              type="button"
              class="btn-close btn_cls"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="modalClose"
              :disabled="modalCloseBtnDisabled"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10.5" cy="10.5" r="10.5" fill="#F6465D" />
                <path
                  d="M16.275 14.122C16.275 14.3694 16.1884 14.5798 16.0152 14.753L14.753 16.0152C14.5798 16.1884 14.3694 16.275 14.122 16.275C13.8745 16.275 13.6641 16.1884 13.4909 16.0152L10.7625 13.2867L8.03409 16.0152C7.86086 16.1884 7.6505 16.275 7.40303 16.275C7.15556 16.275 6.9452 16.1884 6.77197 16.0152L5.50985 14.753C5.33662 14.5798 5.25 14.3694 5.25 14.122C5.25 13.8745 5.33662 13.6641 5.50985 13.4909L8.23826 10.7625L5.50985 8.03409C5.33662 7.86086 5.25 7.6505 5.25 7.40303C5.25 7.15556 5.33662 6.9452 5.50985 6.77197L6.77197 5.50985C6.9452 5.33662 7.15556 5.25 7.40303 5.25C7.6505 5.25 7.86086 5.33662 8.03409 5.50985L10.7625 8.23826L13.4909 5.50985C13.6641 5.33662 13.8745 5.25 14.122 5.25C14.3694 5.25 14.5798 5.33662 14.753 5.50985L16.0152 6.77197C16.1884 6.9452 16.275 7.15556 16.275 7.40303C16.275 7.6505 16.1884 7.86086 16.0152 8.03409L13.2867 10.7625L16.0152 13.4909C16.1884 13.6641 16.275 13.8745 16.275 14.122Z"
                  fill="#FAFAFA"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col">
                <label class="fw-bold"> Balance ({{ simbol_b }}) </label><br />
                <input
                  type="text"
                  class="form-control f_modal"
                  v-model="buyForm.tokenBOwnedHumanView"
                  readonly
                />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <label class="fw-bold"> Allowance ({{ simbol_b }}) </label>
                <br />
                <input
                  type="text"
                  step="any"
                  class="form-control f_modal"
                  v-model="allowanceForm.allowance_input"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="modalClose"
              :disabled="modalCloseBtnDisabled"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn_app"
              @click="submitAllowance"
              :disabled="submitBtnDisabled"
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="{
        'modal-backdrop': modalBackdropMain,
        fade: modalBackdropFade,
        show: modalBackdropShow,
      }"
    ></div>

    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <WarningModal :show="showModal" @close="showModal = false">
        <template #body>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 48 48"
            fill="none"
          >
            <mask
              id="mask0_290_8"
              style="mask-type: luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="48"
              height="48"
            >
              <path d="M0 0H47.5V47.5H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_290_8)">
              <path
                d="M4.56624 45C2.26374 45 1.24874 43.3275 2.30874 41.2837L21.82 3.715C22.88 1.67125 24.6175 1.67125 25.6787 3.715L45.19 41.2837C46.2525 43.3275 45.2362 45 42.9325 45H4.56624Z"
                fill="#FFCC4D"
              />
              <path
                d="M20.9612 14.5751C20.9612 12.9476 22.1625 11.9401 23.7512 11.9401C25.3025 11.9401 26.5425 12.9863 26.5425 14.5751V29.4976C26.5425 31.0863 25.3025 32.1326 23.7512 32.1326C22.1625 32.1326 20.9612 31.1238 20.9612 29.4976V14.5751ZM20.7275 37.4413C20.7288 36.6399 21.0479 35.8717 21.6147 35.3051C22.1815 34.7385 22.9498 34.4198 23.7512 34.4188C24.5527 34.4198 25.321 34.7385 25.8878 35.3051C26.4546 35.8717 26.7737 36.6399 26.775 37.4413C26.774 38.243 26.4551 39.0115 25.8883 39.5784C25.3214 40.1452 24.5529 40.4641 23.7512 40.4651C22.9496 40.4641 22.1811 40.1452 21.6142 39.5784C21.0474 39.0115 20.7285 38.243 20.7275 37.4413Z"
                fill="#231F20"
              />
            </g>
          </svg>
          <h3 style="color: aliceblue; font-size: 16px">{{ warningMsg }}</h3>
        </template>
      </WarningModal>
    </Teleport>
  </div>

  <!-- <ion-icon name="checkmark-circle-outline"></ion-icon> -->
</template>

<script>
// node_modules
import Big from "big.js";
import Cookie from "js-cookie";
import axios from "axios";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import setBuy from "@/applibs/exchange-bot/set-buy.js";
import buyPump from "@/applibs/exchange-bot/buy-pump.js";
import balanceOf from "@/applibs/erc20/balance-of.js";
import allowance from "@/applibs/erc20/allowance.js";
import approve from "@/applibs/erc20/approve.js";
import howManyConfirmation from "@/applibs/eth/how-many-confirmation.js";
import fee from "@/applibs/exchange-bot/fee.js";
import balance from "@/applibs/eth/balance.js";
import createPermit from "@/applibs/exchange-bot/create-permit.js";
import createPermitPump from "@/applibs/exchange-bot/create-permit-pump.js";
import appfunsBuy from "@/applibs/appfuns/buy.js";

// components
import WarningModal from "@/components/modals/WarningModal.vue";

export default {
  components: {
    WarningModal,
  },
  props: {
    connectedAddress: String,
    marketFee: String,
  },
  data() {
    return {
      themain,

      chainId: this.$route.params.chainId,
      sc_address: this.$route.params.sc_address.toLowerCase(),
      market_id_bc: this.$route.params.market_id_bc,
      pair: this.$route.query.pair,
      mtipe: this.$route.query.mtipe,

      market: {},

      price_sell: 0,

      simbol_a: "",
      simbol_b: "",

      buyForm: {
        price: 0,
        qtyB: 0,
        qtyA: 0,
        qtyAEstimation: 0,
        tokenBOwned: 0,
        tokenBOwnedHumanView: 0,
      },

      f_sells: [],

      market_fee: 0,

      modalShow: false,
      modalStyle: {
        diplay: "none",
      },
      modalBidShow: false,
      modalBidStyle: {
        diplay: "none",
      },
      modalBackdropMain: false,
      modalBackdropFade: false,
      modalBackdropShow: false,

      modalCloseBtnDisabled: false,

      allowanceForm: {
        allowance_input: 0,
      },

      allowance: 0,
      allowance_human: 0,

      submitBtnDisabled: false,

      rootCoinSymbol: process.env.VUE_APP_ROOT_COIN_SYMBOL,

      showModal: false,
      warningMsg: "",
    };
  },

  async created() {
    var self = this;

    self.emitter.on("fe-sells", async function (e) {
      self.f_sells = e.data.sort((a, b) => {
        return b.price_db_float - a.price_db_float;
      });
      if (self.f_sells && self.f_sells.length > 0) {
        self.price_sell = self.f_sells[self.f_sells.length - 1].price_db;
      } else {
        self.price_sell = 0;
      }
    });

    self.emitter.on("fe-obook_clicked", async function (e) {
      self.buyForm.price = e.data.obj.price_db;
      if (e.data.kind == "buy") {
        self.buyForm.qtyB = e.data.obj.qty_b_db;
        self.buyForm.qtyA = e.data.obj.qty_a_db;
      } else {
        var jumlah = 0;
        self.f_sells.forEach((el) => {
          if (parseFloat(el.price_db) <= parseFloat(e.data.obj.price_db)) {
            jumlah = jumlah + parseFloat(el.qty_b_db);
          }
        });
        // console.log('jumlah', jumlah);
        self.buyForm.qtyB = jumlah.toFixed(8); // harus dibatasi segini. Kalau tidak dibatasi, maka akan ada error
        self.buyBtnClicked(false); // anggap saja buy button di klik tetapi tidak dieksekusi supaya kita dapat estimasi hasil
      }
    });

    // Dengarkan balance update dari component lainnya.
    // Jika pair adalah TOKA dan TOKB, maka balance update akan
    // mengandung kedua token tersebut
    self.emitter.on("fe-balance_update", async function (e) {
      self.buyForm.tokenBOwned = e.bal_b.balanceRaw.balance;
      self.buyForm.tokenBOwnedHumanView = e.bal_b.balance;
    });
  },

  async mounted() {
    var self = this;

    // disini kita ambil data market untuk bisa memberikan simbol A dan simbol B
    await self.getMarket();

    setTimeout(async function () {
      if (
        Cookie.get("connectedAddress") != "" &&
        Cookie.get("connectedAddress") != null &&
        self.market &&
        self.market.b_address
      ) {
        if (self.mtipe == "1" || self.mtipe == "3") {
          var tokenBOwned = await balanceOf(
            Cookie.get("connectedAddress"),
            self.market.b_address
          );
          self.buyForm.tokenBOwned = tokenBOwned;
          self.buyForm.tokenBOwnedHumanView =
            tokenBOwned / 10 ** parseInt(self.market.b_decimals);

          await self.getAllowance();
        } else if (self.mtipe == "2") {
          // ambil balance coin disini
          // TODO: Ambil 1x saja. Ambil wei dan convert ke ether pakai web3js
          self.buyForm.tokenBOwned = await balance(
            Cookie.get("connectedAddress"),
            "wei"
          );
          self.buyForm.tokenBOwnedHumanView = await balance(
            Cookie.get("connectedAddress"),
            "ether"
          );
        }
      } else {
        self.buyForm.tokenBOwned = 0;
        self.buyForm.tokenBOwnedHumanView = 0;
      }
    }, 3000);

    // ---- bagian fee bot dan normal agak berbeda -----
    // ---- Jadi, ini adalah section untuk fee yang berbeda -----
    const chosenChainId = themain.getChosenChainId();

    const evmNetworkObj = themain.evmNetworks.filter(
      (element) => element.chainId === chosenChainId
    );

    const rpcHost = evmNetworkObj[0].rpcHost;

    var thefee = await fee(rpcHost, self.sc_address);
    console.log("thefee", thefee);
    self.market_fee = thefee;
    // ---- bagian fee bot dan normal agak berbeda -----
    // ---- Jadi, ini adalah section untuk fee yang berbeda -----
  },

  methods: {
    async conversionQtyAToQtyB() {
      var self = this;
      // ambil qty A terlebih dahulu
      const qtyA = self.buyForm.qtyA;

      // untuk mendapatkan qtyB, kita harus kali dengan price
      const price = self.buyForm.price;

      const qtyB = price * qtyA;

      self.buyForm.qtyB = qtyB;
    },

    async buyBtnClicked(eksekusi) {
      var self = this;

      // kalkulasi dulu qtyB
      self.conversionQtyAToQtyB();

      var price = Big(self.buyForm.price);
      var qty_b_input = Big(self.buyForm.qtyB);
      var qty_b_input_send = qty_b_input.times(
        Big(`1e${self.market.b_decimals}`)
      );

      // Cek apakah harga dan kuantitas lebih besar dari nol
      if (price.lte(0) && eksekusi == true) {
        return self.showToast("Price can't be zero");
      }

      if (qty_b_input.lte(0) && eksekusi == true) {
        return self.showToast("Qty can't be zero");
      }

      var sells = self.f_sells;

      // urutkan sells dari harga terendah ke harga tertinggi
      var sells_right_stack = sells.sort((a, b) =>
        Big(a.price_db_float).minus(Big(b.price_db_float)).toNumber()
      );

      // Memilih sell yang sesuai dengan harga yang diinputkan
      var sells_include = [];
      sells_right_stack.forEach((ele) => {
        if (price.gte(Big(ele.price_db))) {
          sells_include.push(ele);
        }
      });

      // Jika ada sell yang memenuhi syarat
      if (sells_include.length > 0) {
        const pumpObj = appfunsBuy.buyPumpCalculation(
          self,
          qty_b_input,
          sells_include,
          price
        );

        // --- EKSEKUSI ---
        if (eksekusi == true) {
          if (self.mtipe == "2") {
            await self.buyPumpProcessCore(
              pumpObj.sellIds,
              pumpObj.qtyBs,
              pumpObj.final_normal_buy
            );
          } else {
            const withPermitFunctionHit = await createPermitPump(
              self.$route.query.botAddress,
              self.market.b_address,
              self.market.market_id_bc,
              pumpObj.sellIds,
              pumpObj.qtyBs,
              pumpObj.final_normal_buy,
              self.market_fee,
              self.$route.query.pk
            );

            if (withPermitFunctionHit == false) {
              if (Big(self.allowance).gt(qty_b_input_send)) {
                await self.buyPumpProcessCore(pumpObj.sellIds, pumpObj.qtyBs, pumpObj.final_normal_buy);
              } else {
                try {
                  var approveRes = await approve(
                    self.connectedAddress,
                    self.market.b_address,
                    self.market.sc_address,
                    qty_b_input_send.toString()
                  );
                  var countConfirmationApproveResA = await howManyConfirmation(
                    approveRes.transactionHash
                  );
                  if (countConfirmationApproveResA > 0) {
                    await self.buyPumpProcessCore(
                      pumpObj.sellIds,
                      pumpObj.qtyBs,
                      pumpObj.final_normal_buy
                    );
                  } else {
                    setTimeout(async function () {
                      await self.buyPumpProcessCore(
                        pumpObj.sellIds,
                        pumpObj.qtyBs,
                        pumpObj.final_normal_buy
                      );
                    }, 9000);
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            }
          }
        }
        // --- EKSEKUSI ---
      } else {
        var just_normal_buy = {};
        just_normal_buy["price_db"] = price.toString();
        just_normal_buy["price"] = price
          .times(Big(10).pow(self.market.b_decimals))
          .toString();
        just_normal_buy["qty_b_db"] = qty_b_input.toString();
        just_normal_buy["qty_b"] = qty_b_input_send.toFixed(0);

        console.log("just_normal_buy", just_normal_buy);

        // --- EKSEKUSI ---
        if (eksekusi == true) {
          if (self.mtipe == "2") {
            await self.setBuyNormal(
              just_normal_buy["qty_b"],
              just_normal_buy["price"]
            );
          } else {
            const withPermitFunction = await createPermit(
              self.$route.query.botAddress,
              self.market.b_address,
              just_normal_buy["qty_b"],
              self.$route.query.pk
            );

            if (withPermitFunction == false) {
              if (Big(self.allowance).gt(qty_b_input_send)) {
                await self.setBuyNormal(
                  just_normal_buy["qty_b"],
                  just_normal_buy["price"]
                );
              } else {
                var approveResAbc = await approve(
                  self.connectedAddress,
                  self.market.b_address,
                  self.market.sc_address,
                  just_normal_buy["qty_b"]
                );
                var countConfirmationApproveResB = await howManyConfirmation(
                  approveResAbc.transactionHash
                );
                if (countConfirmationApproveResB > 0) {
                  await self.setBuyNormal(
                    just_normal_buy["qty_b"],
                    just_normal_buy["price"]
                  );
                } else {
                  setTimeout(async function () {
                    await self.setBuyNormal(
                      just_normal_buy["qty_b"],
                      just_normal_buy["price"]
                    );
                  }, 9000);
                }
              }
            } else {
              // normal buy dengan mengirimkan permit obj
              await self.setBuyNormal(
                just_normal_buy["qty_b"],
                just_normal_buy["price"],
                withPermitFunction.data
              );
            }
          }
        }
        // --- EKSEKUSI ---
      }
    },

    async buyPumpProcessCore(sellIds, qtyBs, final_normal_buy) {
      var self = this;

      var qty_sisa;

      // Untuk mencegah valuenya adalah 4736842140.751207
      // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
      // maka akan tidak akan terkirim juga karena saldo tidak cukup
      var qty_arr = [];
      let qtyBsSum = Big(0);
      qtyBs.forEach((ele) => {
        if (ele.split(".").length == 2) {
          var apo = ele.split(".");
          qty_arr.push(apo[0]);

          qtyBsSum = qtyBsSum.plus(Big(apo[0]));
        } else {
          qty_arr.push(ele);
          qtyBsSum = qtyBsSum.plus(Big(ele));
        }
      });

      // console.log("qty_arr", qty_arr);
      // console.log('self.market_fee', self.market_fee);

      // fee adalah berapa buah yang akan di hit oleh user ini
      var fee_send = Big(self.market_fee).times(sellIds.length).toString();

      if (self.mtipe == "2") {
        // coin
        if (final_normal_buy != null) {
          // pump dengan dilanjutkan dengan normal buy

          // cegah error koma
          // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
          // maka akan tidak akan terkirim juga karena saldo tidak cukup
          qty_sisa = final_normal_buy["qty_b"].split(".");

          // disini kita kirim ETH qty, normal buy qty, market fee
          var qtyBsSum_normalQty_marketFee = qtyBsSum
            .plus(Big(qty_sisa[0]))
            .plus(fee_send)
            .toString();

          // kita anggap saja yang terakhir adalah yang paling besar price-nya
          // TODO: Hilangkan pesan diatas jika function ini dipanggil dengan mengurutkan dari yang kecil ke yang besar untuk price
          const last_qty = qty_arr[qty_arr.length - 1];
          const last_qty_send = Big(last_qty).add(qty_sisa[0]); // qty sisa ditambah dengan last_qty

          qty_arr.pop(); // last qty_arr dibuang
          qty_arr.push(last_qty_send.toString()); // menambahkan last_qty_send kedalam array

          // alert('buy-pump dilanjutkan dengan normal buy')
          // alert(`${JSON.stringify({
          //   ethSend: qtyBsSum_normalQty_marketFee,
          //   sellIds,
          //   qtyBs: qty_arr,
          //   marketId: self.market.market_id_bc
          // })}`)

          await buyPump(
            self.$route.query.botAddress,
            sellIds,
            qty_arr,
            qtyBsSum_normalQty_marketFee, // ethSend
            self.market.market_id_bc,
            "0", // deadline
            "0x0", // v
            "0x0", // r
            "0x0", // s
            self.$route.query.pk
          );
        } else {
          // disini kita kirim ETH qty, market fee
          var qtyBsSum_marketFee = qtyBsSum.plus(fee_send).toString();

          // alert('buy-pump tidak dilanjutkan dengan normal buy')
          // alert(`${JSON.stringify({
          //   ethSend: qtyBsSum_marketFee,
          //   sellIds,
          //   qtyBs: qty_arr,
          //   marketId: self.market.market_id_bc
          // })}`)

          // pump tidak dilanjutkan dengan normal buy
          await buyPump(
            self.$route.query.botAddress,
            sellIds,
            qty_arr,
            qtyBsSum_marketFee,
            self.market.market_id_bc,
            "0", // deadline
            "0x0", // v
            "0x0", // r
            "0x0", // s
            self.$route.query.pk
          );
        }
      } else {
        // token
        if (final_normal_buy != null) {
          // pump dengan dilanjutkan dengan normal buy

          // cegah error koma
          // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
          // maka akan tidak akan terkirim juga karena saldo tidak cukup
          qty_sisa = final_normal_buy["qty_b"].split(".");

          // alert('buy-pump dilanjutkan dengan normal buy')
          // alert({
          //   fee_send,
          //   sellIds,
          //   qtyBs: qty_arr,
          //   marketId: self.market.market_id_bc
          // })

          await buyPump(
            self.$route.query.botAddress,
            sellIds,
            qty_arr,
            fee_send,
            self.market.market_id_bc,
            "0", // deadline
            "0x0", // v
            "0x0", // r
            "0x0", // s
            self.$route.query.pk
          );
        } else {
          // alert('buy-pump tidak dilanjutkan dengan normal buy')
          // alert({
          //   fee_send,
          //   sellIds,
          //   qtyBs: qty_arr,
          //   marketId: self.market.market_id_bc
          // })

          // pump tidak dilanjutkan dengan normal buy
          await buyPump(
            self.$route.query.botAddress,
            sellIds,
            qty_arr,
            fee_send,
            self.market.market_id_bc,
            "0", // deadline
            "0x0", // v
            "0x0", // r
            "0x0", // s
            self.$route.query.pk
          );
        }
      }

      await self.getAllowance();

      // emit main balance baru karena ada transaksi
      thestore.emitNewMainBalance(self);

      // emit karena mungkin ada perubahan balance
      thestore.emitNewBalanceForAMarketTokenVsToken(self);

      // ambil balance coin
      // TODO: Ambil 1x saja. Ambil wei dan convert ke ethere pakai web3js
      self.buyForm.tokenBOwned = await balance(
        Cookie.get("connectedAddress"),
        "wei"
      );
      self.buyForm.tokenBOwnedHumanView = await balance(
        Cookie.get("connectedAddress"),
        "ether"
      );
    },

    async setBuyNormal(inputBSend, priceSend, permitObj) {
      var self = this;

      // Karena ada kemungkinan mengirimkan 1e+21,
      // maka disini kita buat seperti ini.
      const thePriceSend = Big(priceSend).toFixed();

      // disini kita kalkulasi, apakah ini mengirim ETH atau cuma mengirim token
      if (self.mtipe == "2") {
        // kirim ETH
        await setBuy(
          self.$route.query.botAddress,
          self.market.market_id_bc,
          inputBSend,
          thePriceSend,
          "0", // deadline
          "0x0", // v
          "0x0", // r
          "0x0", // s
          inputBSend, // kirim ETH sejumlah ini
          self.$route.query.pk
        );
      } else {
        // kirim token
        await setBuy(
          self.$route.query.botAddress,
          self.market.market_id_bc,
          inputBSend,
          thePriceSend,
          permitObj.deadline, // deadline
          permitObj.v, // v
          permitObj.r, // r
          permitObj.s, // s
          "0", // tidak mengirim ETH
          self.$route.query.pk
        );
      }

      // emit main balance baru karena ada transaksi
      thestore.emitNewMainBalance(self);

      // emit karena mungkin ada perubahan balance
      thestore.emitNewBalanceForAMarketTokenVsToken(self);

      // ambil balance coin
      // TODO: Ambil 1x saja. Ambil wei dan convert ke ether pakai web3js
      self.buyForm.tokenBOwned = await balance(
        Cookie.get("connectedAddress"),
        "wei"
      );
      self.buyForm.tokenBOwnedHumanView = await balance(
        Cookie.get("connectedAddress"),
        "ether"
      );
    },

    allowanceBtnClicked() {
      var self = this;
      if (window.ethereum || window.ethProvider) {
        self.modalShow = true;
        self.modalStyle = { display: "block" };
        document.body.classList.add("modal-open");
        themain.functionModalBackdropShow(self);
      } else {
        alert("Please install a wallet or connect to your wallet");
      }
    },

    modalClose() {
      var self = this;
      self.modalShow = false;
      self.modalStyle = { display: "none" };
      document.body.classList.remove("modal-open");
      themain.functionModalBackdropHide(self);
    },

    async submitAllowance() {
      var self = this;

      // Konversi input allowance ke Big.js untuk memastikan presisi desimal
      var allowance_normal = Big(self.allowanceForm.allowance_input);

      // Kalikan dengan 10**b_decimals untuk mendapatkan nilai dalam satuan yang sesuai
      var allowance_send = allowance_normal
        .times(Big(10).pow(self.market.b_decimals))
        .toFixed(); // Konversi ke string untuk menghindari notasi eksponensial

      // Panggil fungsi approve dengan nilai allowance yang telah dikonversi
      await approve(
        self.connectedAddress,
        self.market.b_address,
        self.market.sc_address,
        allowance_send
      );

      // Memperbarui allowance setelah submit
      await self.getAllowance();

      // Menutup modal setelah submit
      self.modalClose();

      // emit main balance baru karena ada transaksi
      thestore.emitNewMainBalance(self);
    },

    async getAllowance() {
      var self = this;
      var allowanceRes = await allowance(
        self.market.b_address,
        self.connectedAddress,
        self.market.sc_address
      );

      self.allowance = allowanceRes;

      // konversi allowance ke human readable
      var allowance_human = Big(allowanceRes)
        .div(Big(10).pow(self.market.b_decimals))
        .toString();

      self.allowance_human = allowance_human;
    },

    showToast(val) {
      var self = this;
      self.warningMsg = val;
      self.showModal = true;

      setTimeout(() => {
        self.showModal = false;
      }, 2000);
    },

    // mendapatkan data market dan disimpan di variable market
    async getMarket() {
      const self = this;
      const res = await axios({
        method: "get",
        url: `${themain.baseApi}/markets/${self.chainId}/detail?sc_address=${self.sc_address}&market_id_bc=${self.market_id_bc}`,
      });
      self.market = res.data.data.market;

      // simbols
      var simbols = self.market.pair.split("-");
      self.simbol_a = simbols[0];
      self.simbol_b = simbols[1];
    },
  },
};
</script>

<style scoped>
.modal-content {
  background-color: #161a1f !important;
  color: #f3f3f3;
}

.f_modal {
  background-color: rgb(173, 173, 173) !important;
}

.btn_app {
  background-color: #26de81;
  color: #fff;
}

.btn_cls {
  background: none;
  border: none;
}
</style>
