<!-- SEBENARNYA INI ADALAH BUY -->
<template>
  <div class="swap_container">
    <div class="swap-b-component text-light hidden col" ref="swapBComponent">
      <div class="swap_head">
        <h2>New Price {{ priceImpact }}</h2>
        <h2>Impact {{ priceImpactPercent }} %</h2>
      </div>

      <!-- form -->
      <div class="form_wrapper">
        <!-- row A -->
        <div class="form_item">
          <div class="form_left">
            <input
              type="text"
              v-model="swapForm.qtyAEstimation"
              @click="gotoSwapA"
            />
            <p style="opacity: 0">Available:</p>
          </div>

          <div class="form_right">
            <div class="form_simbol">
              {{ simbol_a }}
            </div>
          </div>
        </div>

        <!-- row B -->
        <div class="form_item">
          <div class="form_left">
            <input
              type="text"
              @keyup="inputB(false)"
              v-model="swapForm.qtyB"
              ref="swapBComponentQtyBInput"
            />
            <p>
              Available : {{ swapForm.tokenBOwnedHumanView }} {{ simbol_b }}
            </p>
          </div>

          <div class="form_right">
            <div class="form_simbol">
              {{ simbol_b }}
            </div>
          </div>
        </div>

        <div class="swap_icon">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.381 11.756C1.09886 11.4738 0.940368 11.0911 0.940368 10.692C0.940368 10.293 1.09886 9.91023 1.381 9.62801L10.4107 0.598282C10.6929 0.316147 11.0757 0.157653 11.4747 0.157653C11.8738 0.157653 12.2565 0.316147 12.5387 0.598282L21.5685 9.62801C21.8426 9.91185 21.9943 10.292 21.9909 10.6866C21.9874 11.0812 21.8292 11.4587 21.5501 11.7377C21.2711 12.0167 20.8936 12.175 20.499 12.1784C20.1044 12.1818 19.7243 12.0302 19.4405 11.756L11.4747 3.79029L3.509 11.756C3.22678 12.0382 2.84406 12.1966 2.445 12.1966C2.04594 12.1966 1.66322 12.0382 1.381 11.756ZM1.381 20.7857C1.09886 20.5035 0.940369 20.1208 0.940369 19.7217C0.940369 19.3227 1.09886 18.94 1.381 18.6577L10.4107 9.62801C10.6929 9.34588 11.0757 9.18738 11.4747 9.18738C11.8738 9.18738 12.2565 9.34588 12.5387 9.62801L21.5685 18.6577C21.8426 18.9416 21.9943 19.3217 21.9909 19.7163C21.9874 20.1109 21.8292 20.4884 21.5501 20.7674C21.2711 21.0464 20.8936 21.2047 20.499 21.2082C20.1044 21.2116 19.7243 21.0599 19.4405 20.7857L11.4747 12.82L3.509 20.7857C3.22678 21.0679 2.84406 21.2264 2.445 21.2264C2.04594 21.2264 1.66322 21.0679 1.381 20.7857Z"
              fill="#D3D2D2"
            />
          </svg>
        </div>
      </div>

      <div class="btn_swap">
        <button @click="inputB(true)">Swap</button>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Big from "big.js";
import axios from "axios";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import buyPump from "@/applibs/exchange/buy-pump.js";
import balanceOf from "@/applibs/erc20/balance-of.js";
import allowance from "@/applibs/erc20/allowance.js";
import approve from "@/applibs/erc20/approve.js";
import howManyConfirmation from "@/applibs/eth/how-many-confirmation.js";
import fee from "@/applibs/exchange/fee.js";

// components

export default {
  components: {},
  props: {
    connectedAddress: String,
    marketFee: String,
  },
  data() {
    return {
      themain,

      chainId: this.$route.params.chainId,
      sc_address: this.$route.params.sc_address.toLowerCase(),
      market_id_bc: this.$route.params.market_id_bc,
      pair: this.$route.query.pair,
      mtipe: this.$route.query.mtipe,

      market: {},

      priceImpact: 0,
      priceImpactPercent: 0,

      simbol_a: "",
      simbol_b: "",

      swapForm: {
        qtyB: 0,
        tokenBOwned: 0,
        tokenBOwnedHumanView: 0,

        qtyAEstimation: 0,
      },

      f_sells: [],

      market_fee: 0,

      allowance: 0,
      allowance_human: 0,
    };
  },

  async created() {
    var self = this;

    self.emitter.on("fe-sells", async function (e) {
      self.f_sells = e.data.sort((a, b) => {
        return b.price_db_float - a.price_db_float;
      });
      if (self.f_sells && self.f_sells.length > 0) {
        self.price_sell = self.f_sells[self.f_sells.length - 1].price_db;
      } else {
        self.price_sell = 0;
      }
    });

    // kita dengarkan event yang datang dari swap a form
    self.emitter.on("fe-show_swap_b", function () {
      const element = self.$refs.swapBComponent;
      element.classList.remove("hidden");

      // buat cursor focus pada element input
      const elInput = self.$refs.swapBComponentQtyBInput;
      elInput.focus();
    });
  },

  async mounted() {
    var self = this;

    // disini kita ambil data market untuk bisa memberikan simbol A dan simbol B
    await self.getMarket();

    setTimeout(async function () {
      if (self.connectedAddress != "" && self.market && self.market.b_address) {
        // ambil balance token B
        var tokenBOwned = await balanceOf(
          self.connectedAddress,
          self.market.b_address
        );
        self.swapForm.tokenBOwned = tokenBOwned;
        self.swapForm.tokenBOwnedHumanView =
          tokenBOwned / 10 ** parseInt(self.market.b_decimals);
      } else {
        self.swapForm.tokenBOwned = 0;
        self.swapForm.tokenBOwnedHumanView = 0;
      }

      // ambil allowance dia disini
      await self.getAllowance();
    }, 3000);

    var thefee = await fee();
    // console.log('thefee', thefee);
    self.market_fee = thefee;
  },

  methods: {
    async inputB(eksekusi) {
      var self = this;
      console.log("input B", eksekusi);

      // yang di hit adalah sell
      // yang dimasukkan adalah token B

      // digunakan untuk mencatat blockchain id dari partial sell
      var partial_sell_bc_id = null;
      // digunakan untuk mencatat semua data yang ada sebelum perubahan partial sell
      var partial_sell_when_full_data = null;

      var price = Infinity; // pokoknya harus yang paling besar
      var qty_b_input = Big(self.swapForm.qtyB);
      var qty_b_input_send = qty_b_input.times(
        Big(`1e${self.market.b_decimals}`)
      );
      console.log("qty_b_input_send", qty_b_input_send.toString());

      var sells = self.f_sells;

      // urutkan dulu sell ini dari tinggi sampai rendah
      var sells_right_stack = sells.sort(function (a, b) {
        return a.price_db_float - b.price_db_float;
      });

      // harga dipakai lagi jika ada perdagangan yang tertinggal
      price = sells_right_stack.reduce((max, currentObject) => {
        return Math.max(max, currentObject.price_db_float);
      }, 0);
      price = Big(price);

      console.log("price", price);

      var sells_include = sells_right_stack;

      if (sells_include.length > 0) {
        console.log("sells_include", sells_include);

        var sisa = qty_b_input;
        var index = 0;
        var sisa_arr = [];
        var minus_index = null; // ketemu minus pada index? catat pada variable ini
        sells_include.forEach((ele) => {
          console.log(
            "-- ele",
            `index ${index} -`,
            ele.price_db,
            "-",
            ele.qty_a_db,
            "-",
            ele.qty_b_db
          );

          sisa = parseFloat(sisa) - parseFloat(ele.qty_b_db);
          console.log("sisa", sisa);
          sisa_arr.push({
            index: index,
            sisa: sisa,
            price: parseFloat(ele.price_db),
            qty_b: parseFloat(ele.qty_b_db),
          });

          if (minus_index == null && sisa <= 0) {
            minus_index = index;
          }

          index = index + 1;
          console.log("");
        });

        var final_normal_buy = null;
        // final_smash_list adalah daftar yang dimasukkan dalam buy yang akan disubmit ke blockchain
        // bagian ini ditandai dengan minus_index
        var final_smash_list = [];
        if (minus_index == null) {
          // disini semua sells_include dimasukkan karena tidak ada minus_index
          sells_include.forEach((ele) => {
            final_smash_list.push(ele);
          });
        } else {
          // masukkan sampai minus_index - 1
          for (let i = 0; i < minus_index; i++) {
            final_smash_list.push(sells_include[i]);
          }
        }

        console.log("smash_list init", final_smash_list);

        console.log("sisa_arr", sisa_arr);
        console.log("minus_index", minus_index);

        // row terakhir dari smash, apakah partial atau ada sisa tertinggal
        if (minus_index == null) {
          console.log("ADA BAGIAN TERSISA DI BUY DISINI");

          var sisa_pop = sisa_arr.pop(); // kita ambil bagian terakhir
          console.log(sisa_pop);
          sisa_pop["price_db"] = price.toString();
          sisa_pop["price"] = price
            .times(Big(10).pow(self.market.b_decimals))
            .toString(); // => price * 10**18
          sisa_pop["qty_b_db"] = sisa_pop.sisa;
          sisa_pop["qty_b"] = Big(sisa_pop.sisa)
            .times(Big(10).pow(self.market.b_decimals))
            .toString(); // => sisa * 10**18

          final_normal_buy = sisa_pop;
        } else {
          // disini artinya ada partial sell sehingga sell tersisa
          partial_sell_bc_id = sells_include[minus_index].sell_id_bc;
          partial_sell_when_full_data = JSON.parse(
            document.getElementById(`sell_bc_id_${partial_sell_bc_id}`)
              .innerHTML
          );

          // jika minus_index == 0, artinya dia hanya hit padar row pertama
          if (minus_index == 0) {
            console.log("qty_b_input", qty_b_input);
            console.log("sells_include[0].qty_b_db", sells_include[0].qty_b_db);

            var persentase_dipakai = qty_b_input.div(sells_include[0].qty_b_db); // qty_b_input / parseFloat(sells_include[0].qty_b_db);
            console.log("persentase_dipakai", persentase_dipakai);
            console.log("persentase_dipakai", persentase_dipakai.toString());

            // ambil row pertama dari sells
            var smash_row_zero = sells_include[0];
            smash_row_zero["qty_b_db"] = qty_b_input.toString();
            smash_row_zero["qty_b"] = qty_b_input
              .times(Big(10).pow(self.market.b_decimals))
              .toString();
            smash_row_zero["qty_a_db"] = Big(sells_include[0].qty_a_db)
              .times(Big(persentase_dipakai))
              .toString();
            smash_row_zero["qty_a"] = Big(sells_include[0].qty_a)
              .times(Big(persentase_dipakai))
              .toString();

            // masukkan kedalam smash list
            final_smash_list.push(smash_row_zero);
          } else {
            // determinasi index ini, dipastikan ini adalah full buy bagi buyer, partial sell pada seller
            if (
              sisa_arr[minus_index].qty_b >
              Math.abs(sisa_arr[minus_index - 1].sisa)
            ) {
              // sisa_arr[minus_index].qty_b > Math.abs(sisa_arr[minus_index - 1].sisa
              console.log("partial sell here");

              // kalkulasi row terakhir

              var sisa_terakhir = Big(Math.abs(sisa_arr[minus_index - 1].sisa));
              console.log("sisa_terakhir", sisa_terakhir);

              // persentase yang diambil adalah perbandingannya
              var persentase = sisa_terakhir.div(
                sells_include[minus_index].qty_b_db
              ); // sisa_terakhir / parseFloat(sells_include[minus_index].qty_b_db)
              console.log("persentase ", persentase);

              // disini kita harus modifikasi qty_b
              var smash_list_last = sells_include[minus_index];
              smash_list_last["qty_b_db"] = sisa_terakhir.toString();
              smash_list_last["qty_b"] = sisa_terakhir
                .times(Big(10).pow(self.market.b_decimals))
                .toString();

              // masukkan kedalam smash list
              final_smash_list.push(smash_list_last);
            }
          }
        }

        console.log("FINAL: final_smash_list submit", final_smash_list);
        console.log("FINAL: normal_buy", final_normal_buy);

        var sellIds = [];
        var qtyBs = [];
        final_smash_list.forEach((el) => {
          sellIds.push(String(el.sell_id_bc));
          qtyBs.push(el.qty_b);
        });
        console.log("a", sellIds);
        console.log("b", qtyBs);

        // dapatkan estimasi dengan menjumlahkan semua hasil dari token A
        var estimasiHasilA = final_smash_list.reduce(
          (accumulator, currentObject) => {
            return accumulator + parseFloat(currentObject.qty_a_db);
          },
          0
        );
        console.log("estimasiHasilA", estimasiHasilA);
        self.swapForm.qtyAEstimation = estimasiHasilA;

        const priceImpactCalc = final_smash_list.reduce(
          (max, currentObject) => {
            return Math.max(max, currentObject.price_db_float);
          },
          0
        );
        self.priceImpact = priceImpactCalc;

        // harga sekarang dikurang harga setelahnya, dibagi dengan harga sekarang,
        // setelah itu dikali 100%
        self.priceImpactPercent = (
          ((self.priceImpact - sells_include[0].price_db_float) /
            sells_include[0].price_db_float) *
          100
        ).toFixed(2);
        console.log("self.priceImpactPercent", self.priceImpactPercent);

        // Sepertinya karena kalkulasi, maka ada perubahan pada tampilan dan malahan data pada partial sell
        // dimana seharusnya walaupun partial sell, data belum berubah pada tampilan karena belum terjadi perubahan pada blockchain.
        // Tetapi yang terjadi, tampilan langsung berubah. Sehingga disini harus dikembalikan lagi
        if (partial_sell_bc_id != null) {
          self.f_sells.forEach(function (baliknilai, idxbalik) {
            if (baliknilai.id == partial_sell_when_full_data.id) {
              self.f_sells[idxbalik] = partial_sell_when_full_data;
            }
          });
        }

        // --- EKSEKUSI ---
        if (eksekusi == true) {
          // disini kita cek apakah bisa langsung (coin) atau dicek allowance dulu
          if (self.mtipe == "2") {
            // transfer coin
            await self.buyPumpProcessCore(sellIds, qtyBs, final_normal_buy);
          } else {
            // transfer token
            if (Big(self.allowance).gt(qty_b_input_send)) {
              // lanjut eksekusi sell dump
              await self.buyPumpProcessCore(sellIds, qtyBs, final_normal_buy);
            } else {
              // eksekusi allowance terlebih dahulu
              var approveRes = await approve(
                self.connectedAddress,
                self.market.b_address,
                self.market.sc_address,
                qty_b_input_send.toString()
              );
              // console.log("approveRes", approveRes);
              var countConfirmationApproveRes = await howManyConfirmation(
                approveRes.transactionHash
              );
              if (countConfirmationApproveRes > 0) {
                await self.buyPumpProcessCore(sellIds, qtyBs, final_normal_buy);
              } else {
                setTimeout(async function () {
                  await self.buyPumpProcessCore(
                    sellIds,
                    qtyBs,
                    final_normal_buy
                  );
                }, 9000);
              }
            }
          }
        }
        // --- EKSEKUSI ---
      } else {
        self.showToast();
        // alert("No liquidity");
      }
    },

    async buyPumpProcessCore(sellIds, qtyBs, final_normal_buy) {
      var self = this;

      var qty_sisa;

      // Untuk mencegah valuenya adalah 4736842140.751207
      // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
      // maka akan tidak akan terkirim juga karena saldo tidak cukup
      var qty_arr = [];
      let qtyBsSum = Big("0");
      qtyBs.forEach((ele) => {
        if (ele.split(".").length == 2) {
          var apo = ele.split(".");
          qty_arr.push(apo[0]);

          qtyBsSum.add(Big(apo[0]));
        } else {
          qty_arr.push(ele);
          qtyBsSum = qtyBsSum.add(Big(ele));
        }
      });

      console.log("qty_arr", qty_arr);

      // fee adalah berapa buah yang akan di hit oleh user ini
      var fee_send = Big(self.market_fee).mul(sellIds.length).toString();

      if (self.mtipe == "2") {
        // coin
        if (final_normal_buy != null) {
          // pump dengan dilanjutkan dengan normal buy

          // cegah error koma
          // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
          // maka akan tidak akan terkirim juga karena saldo tidak cukup
          qty_sisa = final_normal_buy["qty_b"].split(".");

          // disini kita kirim ETH qty, normal buy qty, market fee
          var qtyBsSum_normalQty_marketFee = Big(qtyBsSum) // qtyBs
            .add(Big(qty_sisa[0])) // normal qty
            .add(fee_send) // market fee
            .toString();

          await buyPump(
            self.connectedAddress,
            sellIds,
            qty_arr,
            qtyBsSum_normalQty_marketFee,
            self.market.market_id_bc,
            qty_sisa[0],
            final_normal_buy["price"]
          );
        } else {
          // disini kita kirim ETH qty, market fee
          var qtyBsSum_marketFee = Big(qtyBsSum) // qtyBs
            .add(fee_send) // market fee
            .toString();

          // pump tidak dilanjutkan dengan normal buy
          await buyPump(
            self.connectedAddress,
            sellIds,
            qty_arr,
            qtyBsSum_marketFee,
            self.market.market_id_bc,
            0,
            0
          );
        }
      } else {
        if (final_normal_buy != null) {
          // cegah error koma
          // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
          // maka akan tidak akan terkirim juga karena saldo tidak cukup
          qty_sisa = final_normal_buy["qty_b"].split(".");

          await buyPump(
            self.connectedAddress,
            sellIds,
            qty_arr,
            fee_send,
            self.market.market_id_bc,
            qty_sisa[0],
            final_normal_buy["price"]
          );
        } else {
          await buyPump(
            self.connectedAddress,
            sellIds,
            qty_arr,
            fee_send,
            self.market.market_id_bc,
            0,
            0
          );
        }
      }

      await self.getAllowance();

      // emit main balance baru karena ada transaksi
      thestore.emitNewMainBalance(self);
    },

    async getAllowance() {
      var self = this;
      var allowanceRes = await allowance(
        self.market.b_address,
        self.connectedAddress,
        self.market.sc_address
      );

      self.allowance = allowanceRes;

      // konversi allowance ke human readable
      var allowance_human = Big(`${allowanceRes}`)
        .div(`1e${self.market.b_decimals}`)
        .toString();
      console.log("allowance_human", allowance_human);
      self.allowance_human = allowance_human;
    },

    gotoSwapA() {
      var self = this;

      const element = self.$refs.swapBComponent;
      element.classList.add("hidden");

      // emit event agar element swap a dimunculkan
      self.emitter.emit("fe-show_swap_a");
    },

    // mendapatkan data market dan disimpan di variable market
    async getMarket() {
      const self = this;
      const res = await axios({
        method: "get",
        url: `${themain.baseApi}/markets/${self.chainId}/detail?sc_address=${self.sc_address}&market_id_bc=${self.market_id_bc}`,
      });
      self.market = res.data.data.market;

      // simbols
      var simbols = self.market.pair.split("-");
      self.simbol_a = simbols[0];
      self.simbol_b = simbols[1];
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}

.swap-b-component {
  margin: 0 auto;
  width: 400px;
  background-color: #161a1f;
  padding: 20px;
  border-radius: 20px;
}

.swap_head {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.swap_head h2 {
  font-size: 14px;
  color: #d3d2d2;
}

.form_wrapper {
  position: relative;
}

.swap_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 40px;
  height: 40px;
  background-color: #777575;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.form_item {
  padding: 0 10px;
  border: 1px solid #777575;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.form_left input {
  width: 170px;
  font-size: 24px;
  font-weight: 500;
  border: none;
  outline: none;
  background: none;
  color: #d3d2d2;
}

.form_left input:focus {
  border: none;
}

.form_left p {
  font-size: 12px;
  color: #777575;
}

.form_simbol {
  width: 70px;
  height: 35px;
  background-color: #777575;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #d3d2d2;
}

.btn_swap {
  width: 100%;
}

.btn_swap button {
  width: 100%;
  padding: 5px 0;
  border: none;
  border-radius: 20px;
  background-color: #f6465d;
  color: #fff;
}

@media only screen and (max-width: 467px) {
  .swap-b-component {
    width: 300px;
  }
}
</style>
