<!-- SEBENARNYA INI ADALAH SELL -->
<template>
  <div class="swap-a-component" ref="swapAComponent">
    <div class="swap_head">
      <h2>New Price {{ priceImpact }}</h2>
      <h2>Impact {{ priceImpactPercent }} %</h2>
    </div>

    <!-- form -->
    <div class="form_wrapper">
      <!-- row A -->
      <div class="form_item">
        <div class="form_left">
          <input
            type="text"
            v-model="swapForm.qtyA"
            @keyup="inputA(false)"
            ref="swapAComponentQtyAInput"
          />
          <p>Available: {{ swapForm.tokenAOwnedHumanView }} {{ simbol_a }}</p>
        </div>

        <div class="form_right">
          <div class="form_simbol">
            {{ simbol_a }}
          </div>
        </div>
      </div>

      <!-- row B -->
      <div class="form_item">
        <div class="form_left">
          <input
            type="text"
            v-model="swapForm.qtyBEstimation"
            @click="gotoSwapB"
          />
          <p style="opacity: 0">Available:</p>
        </div>

        <div class="form_right">
          <div class="form_simbol">
            {{ simbol_b }}
          </div>
        </div>
      </div>

      <div class="swap_icon">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.5503 9.62801C21.8324 9.91023 21.9909 10.293 21.9909 10.692C21.9909 11.0911 21.8324 11.4738 21.5503 11.756L12.5205 20.7858C12.2383 21.0679 11.8556 21.2264 11.4565 21.2264C11.0575 21.2264 10.6748 21.0679 10.3925 20.7858L1.36281 11.756C1.08867 11.4722 0.93698 11.092 0.940409 10.6974C0.943838 10.3028 1.10211 9.92538 1.38114 9.64635C1.66018 9.36732 2.03764 9.20904 2.43223 9.20561C2.82683 9.20218 3.20698 9.35387 3.49082 9.62801L11.4565 17.5937L19.4223 9.62801C19.7045 9.34588 20.0872 9.18738 20.4863 9.18738C20.8853 9.18738 21.2681 9.34588 21.5503 9.62801ZM21.5503 0.598284C21.8324 0.880505 21.9909 1.26323 21.9909 1.66229C21.9909 2.06135 21.8324 2.44407 21.5503 2.72629L12.5205 11.756C12.2383 12.0382 11.8556 12.1967 11.4565 12.1967C11.0575 12.1967 10.6748 12.0382 10.3925 11.756L1.36281 2.72629C1.08867 2.44245 0.93698 2.0623 0.940409 1.6677C0.943838 1.27311 1.10211 0.895648 1.38114 0.616617C1.66018 0.337586 2.03764 0.179311 2.43223 0.175882C2.82683 0.172453 3.20698 0.324145 3.49082 0.598285L11.4565 8.56401L19.4223 0.598284C19.7045 0.316149 20.0872 0.157654 20.4863 0.157654C20.8853 0.157654 21.2681 0.316149 21.5503 0.598284Z"
            fill="#D3D2D2"
          />
        </svg>
      </div>
    </div>

    <div class="btn_swap">
      <button @click="inputA(true)">Swap</button>
    </div>

    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <WarningModal :show="showModal" @close="showModal = false">
        <template #body>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 48 48"
            fill="none"
          >
            <mask
              id="mask0_290_8"
              style="mask-type: luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="48"
              height="48"
            >
              <path d="M0 0H47.5V47.5H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_290_8)">
              <path
                d="M4.56624 45C2.26374 45 1.24874 43.3275 2.30874 41.2837L21.82 3.715C22.88 1.67125 24.6175 1.67125 25.6787 3.715L45.19 41.2837C46.2525 43.3275 45.2362 45 42.9325 45H4.56624Z"
                fill="#FFCC4D"
              />
              <path
                d="M20.9612 14.5751C20.9612 12.9476 22.1625 11.9401 23.7512 11.9401C25.3025 11.9401 26.5425 12.9863 26.5425 14.5751V29.4976C26.5425 31.0863 25.3025 32.1326 23.7512 32.1326C22.1625 32.1326 20.9612 31.1238 20.9612 29.4976V14.5751ZM20.7275 37.4413C20.7288 36.6399 21.0479 35.8717 21.6147 35.3051C22.1815 34.7385 22.9498 34.4198 23.7512 34.4188C24.5527 34.4198 25.321 34.7385 25.8878 35.3051C26.4546 35.8717 26.7737 36.6399 26.775 37.4413C26.774 38.243 26.4551 39.0115 25.8883 39.5784C25.3214 40.1452 24.5529 40.4641 23.7512 40.4651C22.9496 40.4641 22.1811 40.1452 21.6142 39.5784C21.0474 39.0115 20.7285 38.243 20.7275 37.4413Z"
                fill="#231F20"
              />
            </g>
          </svg>
          <h3 style="color: aliceblue; font-size: 16px">{{ warningMsg }}</h3>
        </template>
      </WarningModal>
    </Teleport>
  </div>
</template>

<script>
// node_modules
import Big from "big.js";
import axios from "axios";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import sellDump from "@/applibs/exchange/sell-dump.js";
import balanceOf from "@/applibs/erc20/balance-of.js";
import allowance from "@/applibs/erc20/allowance.js";
import approve from "@/applibs/erc20/approve.js";
import howManyConfirmation from "@/applibs/eth/how-many-confirmation.js";
import fee from "@/applibs/exchange/fee.js";

// components
import WarningModal from "@/components/modals/WarningModal.vue";

export default {
  components: {
    WarningModal,
  },
  props: {
    connectedAddress: String,
    marketFee: String,
  },
  data() {
    return {
      themain,

      chainId: this.$route.params.chainId,
      sc_address: this.$route.params.sc_address.toLowerCase(),
      market_id_bc: this.$route.params.market_id_bc,
      pair: this.$route.query.pair,
      mtipe: this.$route.query.mtipe,

      market: {},

      priceImpact: 0,
      priceImpactPercent: 0,

      simbol_a: "",
      simbol_b: "",

      swapForm: {
        qtyA: 0,
        tokenAOwned: 0,
        tokenAOwnedHumanView: 0,

        qtyBEstimation: 0,
      },

      f_buys: [],

      market_fee: 0,

      allowance: 0,
      allowance_human: 0,

      showModal: false,
      warningMsg: "",
    };
  },

  async created() {
    var self = this;

    self.emitter.on("fe-buys", function (e) {
      self.f_buys = e.data.sort((a, b) => {
        return b.price_db_float - a.price_db_float;
      });
      if (self.f_buys && self.f_buys.length > 0) {
        self.price_buy = self.f_buys[0].price_db;
      } else {
        self.price_buy = 0;
      }
    });

    // kita dengarkan event yang datang dari swap b form
    self.emitter.on("fe-show_swap_a", function () {
      const element = self.$refs.swapAComponent;
      element.classList.remove("hidden");

      // buat cursor focus pada element input
      const elInput = self.$refs.swapAComponentQtyAInput;
      elInput.focus();
    });
  },

  async mounted() {
    var self = this;

    // disini kita ambil data market untuk bisa memberikan simbol A dan simbol B
    await self.getMarket();

    setTimeout(async function () {
      if (self.connectedAddress != "" && self.market && self.market.b_address) {
        // ambil balance token A
        var tokenAOwned = await balanceOf(
          self.connectedAddress,
          self.market.a_address
        );
        self.swapForm.tokenAOwned = tokenAOwned;
        self.swapForm.tokenAOwnedHumanView =
          tokenAOwned / 10 ** parseInt(self.market.a_decimals);
      } else {
        self.swapForm.tokenAOwned = 0;
        self.swapForm.tokenAOwnedHumanView = 0;
      }

      // ambil allowance dia disini
      await self.getAllowance();
    }, 3000);

    var thefee = await fee();
    // console.log('thefee', thefee);
    self.market_fee = thefee;
  },

  methods: {
    async inputA(eksekusi) {
      var self = this;
      console.log("input A");

      // yang di hit adalah buy
      // yang dimasukkan adalah token A

      // digunakan untuk mencatat blockchain id dari partial buy
      var partial_buy_bc_id = null;
      // digunakan untuk mencatat semua data yang ada sebelum perubahan partial buy
      var partial_buy_when_full_data = null;

      // anggap selalu dump sehingga harga dijadikan 0 saja
      var price = Big(0);

      // tangkap berapa token A yang dimasukkan
      var qty_a_input = Big(self.swapForm.qtyA);
      var qty_a_input_send = qty_a_input.times(
        Big(`1e${self.market.a_decimals}`)
      );

      // ambil semua row buy
      // console.log('self.f_buys', self.f_buys);

      if (self.f_buys.length > 0) {
        // urutkan dulu buy ini dari tinggi sampai rendah
        var buys_right_stack = self.f_buys.sort(function (a, b) {
          return b.price_db_float - a.price_db_float;
        });

        var buys_include = [];
        buys_right_stack.forEach((ele) => {
          if (price.lte(Big(ele.price_db))) {
            buys_include.push(ele);
          }
        });

        var sisa = qty_a_input;
        var index = 0;
        var sisa_arr = [];
        var minus_index = null; // ketemu minus pada index? catat pada variable ini

        buys_include.forEach((ele) => {
          console.log(
            "-- ele",
            `index ${index} -`,
            ele.price_db,
            "-",
            ele.qty_a_db,
            "-",
            ele.qty_b_db
          );

          sisa = parseFloat(sisa) - parseFloat(ele.qty_a_db);
          console.log("sisa", sisa);
          sisa_arr.push({
            index: index,
            sisa: sisa,
            price: parseFloat(ele.price_db),
            qty_a: parseFloat(ele.qty_a_db),
          });

          if (minus_index == null && sisa <= 0) {
            minus_index = index;
          }

          index = index + 1;
          console.log("");
        });

        var final_normal_sell = null;
        // final_smash_list adalah daftar yang dimasukkan dalam sell yang akan disubmit ke blockchain
        // bagian ini ditandai dengan minus_index
        var final_smash_list = [];
        if (minus_index == null) {
          // disini semua buys_include dimasukkan karena tidak ada minus_index
          buys_include.forEach((ele) => {
            final_smash_list.push(ele);
          });
        } else {
          // masukkan sampai minus_index - 1
          for (let i = 0; i < minus_index; i++) {
            final_smash_list.push(buys_include[i]);
          }
        }

        console.log("smash_list init", final_smash_list);

        console.log("sisa_arr", sisa_arr);
        console.log("minus_index", minus_index);

        // row terakhir dari smash, apakah partial atau ada sisa sell tertinggal
        if (minus_index == null) {
          console.log("ADA BAGIAN TERSISA DI SELL DISINI");

          var sisa_pop = sisa_arr.pop(); // kita ambil bagian terakhir
          console.log(sisa_pop);
          sisa_pop["price_db"] = price.toString();
          sisa_pop["price"] = price
            .times(Big(10).pow(self.market.b_decimals))
            .toString(); // => price * 10**18
          sisa_pop["qty_a_db"] = sisa_pop.sisa;
          sisa_pop["qty_a"] = Big(sisa_pop.sisa)
            .times(Big(10).pow(self.market.a_decimals))
            .toString(); // => sisa * 10**18

          final_normal_sell = sisa_pop;
        } else {
          // disini artinya ada partial buy sehingga buy tersisa
          partial_buy_bc_id = buys_include[minus_index].buy_id_bc;
          partial_buy_when_full_data = JSON.parse(
            document.getElementById(`buy_bc_id_${partial_buy_bc_id}`).innerHTML
          );

          // jika minus_index == 0, artinya dia hanya hit pada row pertama
          if (minus_index == 0) {
            console.log("qty_a_input", qty_a_input);
            console.log("buys_include[0].qty_a_db", buys_include[0].qty_a_db);

            var persentase_dipakai = qty_a_input.div(buys_include[0].qty_a_db); // qty_a_input / parseFloat(buys_include[0].qty_a_db);
            console.log("persentase_dipakai", persentase_dipakai);
            console.log("persentase_dipakai", persentase_dipakai.toString());

            // ambil row pertama dari buys
            var smash_row_zero = buys_include[0];
            smash_row_zero["qty_a_db"] = qty_a_input.toString();
            smash_row_zero["qty_a"] = qty_a_input
              .times(Big(10).pow(self.market.a_decimals))
              .toString();
            smash_row_zero["qty_b_db"] = Big(buys_include[0].qty_b_db)
              .times(Big(persentase_dipakai))
              .toString();
            smash_row_zero["qty_b"] = Big(buys_include[0].qty_b)
              .times(Big(persentase_dipakai))
              .toString();

            // masukkan kedalam smash list
            final_smash_list.push(smash_row_zero);
          } else {
            // determinasi index ini, dipastikan ini adalah full sell bagi seller, partial buy pada buyer
            if (
              sisa_arr[minus_index].qty_a >
              Math.abs(sisa_arr[minus_index - 1].sisa)
            ) {
              // sisa_arr[minus_index].qty_a > Math.abs(sisa_arr[minus_index - 1].sisa
              console.log("partial sell here");

              // kalkulasi row terakhir

              var sisa_terakhir = Big(Math.abs(sisa_arr[minus_index - 1].sisa));
              console.log("sisa_terakhir", sisa_terakhir);

              // persentase yang diambil adalah perbandingannya
              var persentase = sisa_terakhir.div(
                buys_include[minus_index].qty_a_db
              ); // sisa_terakhir / parseFloat(buys_include[minus_index].qty_a_db)
              console.log("persentase ", persentase);

              // disini kita harus modifikasi qty_a
              var smash_list_last = buys_include[minus_index];
              smash_list_last["qty_a_db"] = sisa_terakhir.toString();
              smash_list_last["qty_a"] = sisa_terakhir
                .times(Big(10).pow(self.market.a_decimals))
                .toString();

              // masukkan kedalam smash list
              final_smash_list.push(smash_list_last);
            }
          }
        }

        console.log("FINAL: final_smash_list submit", final_smash_list);
        console.log("FINAL: normal_sell", final_normal_sell);

        var buyIds = [];
        var qtyAs = [];
        final_smash_list.forEach((el) => {
          buyIds.push(String(el.buy_id_bc));
          qtyAs.push(el.qty_a);
        });
        console.log("a", buyIds);
        console.log("b", qtyAs);

        // dapatkan estimasi dengan menjumlahkan semua hasil dari token B
        var estimasiHasilB = final_smash_list.reduce(
          (accumulator, currentObject) => {
            return accumulator + parseFloat(currentObject.qty_b_db);
          },
          0
        );
        console.log("estimasiHasilB", estimasiHasilB);
        self.swapForm.qtyBEstimation = estimasiHasilB;

        const priceImpactCalc = final_smash_list.reduce(
          (min, currentObject) => {
            return Math.min(min, currentObject.price_db_float);
          },
          Infinity
        );
        self.priceImpact = priceImpactCalc;

        console.log("higher price", buys_include[0].price_db_float);
        console.log("self.priceImpact", self.priceImpact);

        // harga sekarang dikurang harga setelahnya, dibagi dengan harga sekarang,
        // setelah itu dikali 100%
        self.priceImpactPercent = (
          ((self.priceImpact - buys_include[0].price_db_float) /
            buys_include[0].price_db_float) *
          100
        ).toFixed(2);
        console.log("self.priceImpactPercent", self.priceImpactPercent);

        // Sepertinya karena kalkulasi, maka ada perubahan pada tampilan dan malahan data pada partial buy
        // dimana seharusnya walaupun partial buy, data belum berubah pada tampilan karena belum terjadi perubahan pada blockchain.
        // Tetapi yang terjadi, tampilan langsung berubah. Sehingga disini harus dikembalikan lagi
        if (partial_buy_bc_id != null) {
          self.f_buys.forEach(function (baliknilai, idxbalik) {
            if (baliknilai.id == partial_buy_when_full_data.id) {
              self.f_buys[idxbalik] = partial_buy_when_full_data;
            }
          });
        }

        // --- EKSEKUSI ---
        if (eksekusi == true) {
          // Disini kita cek apakah bisa langsung (coin) atau tidak langsung
          // Tidak langsung artinya kita harus cek allowance sebelum sellDump
          if (self.mtipe == "3") {
            // transfer coin
            await self.sellDumpProcessCore(buyIds, qtyAs, final_normal_sell);
          } else {
            // transfer token
            if (Big(self.allowance).gt(qty_a_input_send)) {
              // lanjut eksekusi sell dump
              await self.sellDumpProcessCore(buyIds, qtyAs, final_normal_sell);
            } else {
              // eksekusi allowance terlebih dahulu
              var approveRes = await approve(
                self.connectedAddress,
                self.market.a_address,
                self.market.sc_address,
                qty_a_input_send.toString()
              );
              // console.log('approveRes', approveRes);
              var countConfirmationApproveRes = await howManyConfirmation(
                approveRes.transactionHash
              );
              if (countConfirmationApproveRes > 0) {
                await self.sellDumpProcessCore(
                  buyIds,
                  qtyAs,
                  final_normal_sell
                );
              } else {
                setTimeout(async function () {
                  await self.sellDumpProcessCore(
                    buyIds,
                    qtyAs,
                    final_normal_sell
                  );
                }, 9000);
              }
            }
          }
        }
        // --- EKSEKUSI ---
      } else {
        self.showToast();
        // alert("No liquidity");
      }
    },

    async sellDumpProcessCore(buyIds, qtyAs, final_normal_sell) {
      var self = this;

      var qty_sisa;

      // Untuk mencegah valuenya adalah 4736842140.751207
      // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
      // maka akan tidak akan terkirim juga karena saldo tidak cukup
      var qty_arr = [];
      let qtyAsSum = Big("0");
      qtyAs.forEach((ele) => {
        if (ele.split(".").length == 2) {
          var apo = ele.split(".");
          qty_arr.push(apo[0]);

          qtyAsSum.add(Big(apo[0]));
        } else {
          qty_arr.push(ele);
          qtyAsSum = qtyAsSum.add(Big(ele));
        }
      });

      // fee adalah berapa buah yang akan di hit oleh user ini
      var fee_send = Big(self.market_fee).mul(buyIds.length).toString();

      if (self.mtipe == "3") {
        // coin

        if (final_normal_sell != null) {
          // dump dengan dilanjutkan dengan normal sell

          // cegah error koma
          // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
          // maka akan tidak akan terkirim juga karena saldo tidak cukup
          qty_sisa = final_normal_sell["qty_a"].split(".");

          // disini kita kirim ETH qty, normal buy qty, market fee
          var qtyAsSum_normalQty_marketFee = Big(qtyAsSum) // qtyAs
            .add(Big(qty_sisa[0])) // normal qty
            .add(fee_send) // market fee
            .toString();

          await sellDump(
            self.connectedAddress,
            buyIds,
            qty_arr,
            qtyAsSum_normalQty_marketFee,
            self.market.market_id_bc,
            qty_sisa[0],
            final_normal_sell["price"]
          );
        } else {
          // disini kita kirim ETH qty, market fee
          var qtyAsSum_marketFee = Big(qtyAsSum) // qtyAs
            .add(fee_send) // market fee
            .toString();

          await sellDump(
            self.connectedAddress,
            buyIds,
            qty_arr,
            qtyAsSum_marketFee,
            self.market.market_id_bc,
            0,
            0
          );
        }
      } else {
        if (final_normal_sell != null) {
          // cegah error koma
          qty_sisa = final_normal_sell["qty_a"].split(".");

          await sellDump(
            self.connectedAddress,
            buyIds,
            qty_arr,
            fee_send,
            self.market.market_id_bc,
            qty_sisa[0],
            final_normal_sell["price"]
          );
        } else {
          await sellDump(
            self.connectedAddress,
            buyIds,
            qty_arr,
            fee_send,
            self.market.market_id_bc,
            0,
            0
          );
        }
      }

      self.getAllowance();

      // emit main balance baru karena ada transaksi
      thestore.emitNewMainBalance(self);
    },

    async getAllowance() {
      var self = this;
      var allowanceRes = await allowance(
        self.market.a_address,
        self.connectedAddress,
        self.market.sc_address
      );

      self.allowance = allowanceRes;

      // konversi allowance ke human readable
      var allowance_human = Big(`${allowanceRes}`)
        .div(`1e${self.market.a_decimals}`)
        .toString();
      console.log("allowance_human", allowance_human);
      self.allowance_human = allowance_human;
    },

    gotoSwapB() {
      var self = this;

      // sembunyikan element ini
      const element = self.$refs.swapAComponent;
      element.classList.add("hidden");

      // emit event agar element swap b dimunculkan
      self.emitter.emit("fe-show_swap_b");
    },

    showToast() {
      var self = this;
      self.warningMsg = "No liquidity";
      self.showModal = true;

      setTimeout(() => {
        self.showModal = false;
      }, 2000);
    },

    // mendapatkan data market dan disimpan di variable market
    async getMarket() {
      const self = this;
      const res = await axios({
        method: "get",
        url: `${themain.baseApi}/markets/${self.chainId}/detail?sc_address=${self.sc_address}&market_id_bc=${self.market_id_bc}`,
      });
      self.market = res.data.data.market;

      // simbols
      var simbols = self.market.pair.split("-");
      self.simbol_a = simbols[0];
      self.simbol_b = simbols[1];
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}

.swap-a-component {
  margin: 0 auto;
  width: 400px;
  background-color: #161a1f;
  padding: 20px;
  border-radius: 20px;
}

.swap_head {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.swap_head h2 {
  font-size: 14px;
  color: #d3d2d2;
}

.form_wrapper {
  position: relative;
}

.swap_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 40px;
  height: 40px;
  background-color: #777575;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.form_item {
  padding: 0 10px;
  border: 1px solid #777575;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.form_left input {
  width: 170px;
  font-size: 24px;
  font-weight: 500;
  border: none;
  outline: none;
  background: none;
  color: #d3d2d2;
}

.form_left input:focus {
  border: none;
}

.form_left p {
  font-size: 12px;
  color: #777575;
}

.form_simbol {
  width: 70px;
  height: 35px;
  background-color: #777575;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #d3d2d2;
}

.btn_swap {
  width: 100%;
}

.btn_swap button {
  width: 100%;
  padding: 5px 0;
  border: none;
  border-radius: 20px;
  background-color: #f6465d;
  color: #fff;
}

@media only screen and (max-width: 467px) {
  .swap-a-component {
    width: 300px;
  }
}
</style>
